.badge {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .badge {
    position: relative;
    width: 55px;
    height: 55px;
    background: #ff4747;
    z-index: 1;
    
    flex-direction: column;
    justify-content: space-evenly;
    cursor: pointer;
    transition: all .3s ease;
  }
  .badge:before, .badge:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ff4747;
    z-index: -1;
  }
  .badge:before { transform: rotate(60deg); }
  .badge:after { transform: rotate(30deg); }
  .price-badge, .text-badge {
    color: #FFF;
    font-weight: bold;
    margin: 0;
    pointer-events: none;
  }
  .price-badge {
    font-size: 10px;
  }
  .text-badge {
    font-size: 10px;
    font-variant: small-caps;
    transform: rotate(-10deg);
    margin-bottom: 5%;
  }

  .badges{
      margin: 1rem 0;
  }

  .badges li{
      display: inline-block;
  }

  .badge.small{
      width: 35px;
      height: 35px;
  }

  .badge.small .text-badge{
      font-size: 7px;
  }