.loading{
    animation-name: loading;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    transform-origin: 50% top 0px;
    width: 70px;
}

@keyframes loading {
    from {
        transform: rotateY(0);
    }
    to{
        transform: rotateY(360deg);
    }
}