
:root{
    --border-radius: 5px;
}

.tabs-component img {
    max-width: 100%;
    vertical-align: middle;
}
  
.tabs-component {
margin: auto;
padding: 40px;
}

.tab-links {
padding: 0;
/* margin: 0 auto 20px; */
list-style: none;
max-width: 400px;
display: flex;
justify-content: space-between;
}

.tab {
position: relative;
}

.tab a {
text-decoration: none;
color: var(--black);
}

.tab::before {
content: "";
width: 100%;
height: 100%;
opacity: 0.2;
position: absolute;
left: 0;
/* border-radius: var(--border-radius); */
background: none;
transition: background 0.5s ease;
transform: skew(.99turn);
}

.tab svg {
height: 15px;
width: 15px;
min-width: 15px;
fill: var(--black);
transition: fill 0.5s ease;
}

.tab.active::before {
background: var(--active-color);
}

.tab span {
font-weight: 700;
margin-left: 10px;
transition: color 0.5s ease;
}

.tab.active span {
color: var(--active-color);
}

.tab.active svg {
fill: var(--active-color);
}

.tab a {
padding: 16px;
display: flex;
align-items: center;
font-size: 1rem;
overflow: hidden;
position: relative;
}

.cards {
display: flex;
flex-wrap: wrap;
justify-content: space-between;
margin-top: 40px;
}

.content-card {
width: 48%;
margin-bottom: 26px;
}

.content-card .info::after {
content: "";
display: block;
width: 100%;
height: 3px;
bottom: -5px;
background: var(--active-color);
opacity: 0.5;
}

.content-card img {
border-radius: 6px;
}

.content-card h3 {
margin: 0 0 5px;
}

.content-card .info {
padding: 10px 0;
}


@media only screen and (max-width: 500px){
    .tabs-component{
        padding: 1rem;
        padding-top: 2rem;
    }

    .tab-links{
        max-width: 100%;
        overflow-x: scroll;
    }
}