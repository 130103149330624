@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@302;400;900&display=swap');

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

 *{
  font-family: 'Raleway', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button{
  cursor: pointer;
}

/* a, a:active, a:visited{
  color: black;
} */

:root{
  --primary-dark: #282c34;
  --max-width: 992px;
}

.no-touch ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.no-touch ::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.no-touch ::-webkit-scrollbar-thumb {
  background: #ababab;
  border: 0px none #ffffff;
  border-radius: 50px;
}
.no-touch ::-webkit-scrollbar-thumb:hover {
  background: #7d7d7d;
}
.no-touch ::-webkit-scrollbar-thumb:active {
  background: #000000;
}
.no-touch ::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 50px;
}
.no-touch ::-webkit-scrollbar-track:hover {
  background: #666666;
}
.no-touch ::-webkit-scrollbar-track:active {
  background: #333333;
}
.no-touch ::-webkit-scrollbar-corner {
  background: transparent;
}