.avatar{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    /* transform: skew(.98turn); */
    overflow: hidden;
    margin: auto;
}

.avatar > img{
    width: 100%;
    height: 100%;
}

