@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@302;400;900&display=swap);
body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

 *{
  font-family: 'Raleway', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button{
  cursor: pointer;
}

/* a, a:active, a:visited{
  color: black;
} */

:root{
  --primary-dark: #282c34;
  --max-width: 992px;
}

.no-touch ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.no-touch ::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.no-touch ::-webkit-scrollbar-thumb {
  background: #ababab;
  border: 0px none #ffffff;
  border-radius: 50px;
}
.no-touch ::-webkit-scrollbar-thumb:hover {
  background: #7d7d7d;
}
.no-touch ::-webkit-scrollbar-thumb:active {
  background: #000000;
}
.no-touch ::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 50px;
}
.no-touch ::-webkit-scrollbar-track:hover {
  background: #666666;
}
.no-touch ::-webkit-scrollbar-track:active {
  background: #333333;
}
.no-touch ::-webkit-scrollbar-corner {
  background: transparent;
}
html,body,#root{
  height: 100%;
}

.App {
  text-align: center;
  height: 100%;
  min-height: -webkit-fill-available;
  color: white;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.content{
  height: calc(100% - 70px);
  padding-top: 70px;
  position: relative;
  /* overflow-y: hidden; */
}

code{
  background-color: lightgray;
  padding: 1rem;
  margin: .5rem 0;
  border-radius: 5px;
  position: relative;
  white-space: nowrap;
}

code::before{
  content: "";
  position: absolute;
  top: .75rem;
  left: .5rem;
  bottom: .75rem;
  width: 1.5px;
  background: black;
}

.inner {
  max-width: 992px;
  margin: auto;
}

.rating{
  color: gold;
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  background: black;
  padding: 4px;
  z-index: 1;
}

.card-content-container.open .rating{
  font-size: 1.5rem;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.card-list {
  display: flex;
  /* flex-wrap: wrap; */
  align-content: flex-start;
  width: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
}

.card {
  position: relative;
  padding: 25px;
  height: 200px;
  flex: 0 0 40%;
  max-width: 350px;
}

/* .card:nth-child(4n + 1),
.card:nth-child(4n + 4) {
  flex: 0 0 60%;
  max-width: 60%;
}

.card:nth-child(odd) {
  padding-left: 0;
}

.card:nth-child(even) {
  padding-right: 0;
} */

.card-content-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  /* pointer-events: none; */
}

.card-content-container.open {
  top: 70px;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1;
  overflow: hidden;
  padding: 40px 0;
}

.card-content {
  pointer-events: auto;
  position: relative;
  border-radius: 20px;
  background: #1c1c1e;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.open .card-content {
  height: auto;
  max-width: 700px;
  overflow: hidden;
  /* pointer-events: none; */
}

.card-open-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.card-image-container {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  /* height: 420px;
  width: 100vw; */
}

.open .card-image-container,
.open .title-container {
  z-index: 1;
}

.title-container {
  position: absolute;
  top: 15px;
  left: 15px;
  max-width: 300px;
  text-align: left;
  padding: .5rem;
}

.title-container > h2, .title-container > h3, .title-container > h4, .title-container > h5, .title-container > .category, .title-container > .street-address{
  background: black;
  padding: 2px .2em;
}

.title-container > h2, .title-container > h3, .title-container > h4, .title-container > h5{
  display: table;
}

.open .title-container{
  transition: background .3s ease;
  transition-delay: 0s;
}

.open .title-container:hover{
  background: black;
  cursor: pointer;
  transition-delay: .5s;
}

.opened_now{
  background-color: green !important;
}

.closing_soon{
  background-color: #ff9f00 !important;
}

.opened{
  margin-bottom: .25rem;
}

.opened_next{
  display: table;
  font-weight: lighter;
  font-size: .7rem;
  background-color: black;
  padding: 2px;
}

.public_holiday{
  display: table;
  font-weight: lighter;
  font-size: .7rem;
  color: black;
  background-color: yellow;
  padding: 2px;
}



.open .title-container {
  top: 30px;
  left: 30px;
}

.category {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
}

.overlay {
  z-index: 1;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  will-change: opacity;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  /* max-width: 990px; */
}

.overlay a {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  left: 50%;

  -webkit-transform: translateX(-50%);

          transform: translateX(-50%);
}

.content-container {
  padding: 290px 35px 35px 35px;
  max-width: 700px;
  /* width: 90vw; */
}

p {
  color: #9d9ca1;
  font-size: 20px;
  line-height: 28px;
}

/* @media only screen and (max-width: 800px) {
  .card {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .card:nth-child(4n + 1),
  .card:nth-child(4n + 4) {
    flex: 0 0 50%;
    max-width: 50%;
  }
} */

@media only screen and (max-width: 600px) {
  .card {
     max-width: 90%;
     flex: 1 0 90%;
  }

  /* .card:nth-child(4n + 1),
  .card:nth-child(4n + 4) {
    flex: 1 0 100%;
    max-width: 100%;
  }

  .card-content-container.open {
    padding: 0;
  } */
}

@media only screen and (max-width: 500px) {
  .crop{
      max-width: 60vw;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .crop ._title{
      width: calc(60vw - 60px);
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
  }
  .crop .dots{
      display: inline-block;
  }
}

.verified_wrapper{
  display: inline-block;
  margin-left: .5rem;
  cursor: pointer;
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.pending{
  width: 17.5px;
  height: 17.5px;
  border-radius: 50%;
  z-index: 1;
  background: #1294f1;
  color: white;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verified {
  display: flex;
  align-items: center;
  justify-content: center;
}

.verified {
  position: relative;
  width: 15px;
  height: 15px;
  background: #2ec78f;
  z-index: 1;
  font-size: 10px;
  flex-direction: column;
  justify-content: space-evenly;
}
.verified:before, .verified:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #2ec78f;
  z-index: -1;
}
.verified:before { -webkit-transform: rotate(60deg); transform: rotate(60deg); }
.verified:after { -webkit-transform: rotate(30deg); transform: rotate(30deg); }

.__react_component_tooltip{
  padding: .5rem !important;
  pointer-events: inherit !important;
}

.__react_component_tooltip > span {
  font-size: .65rem;
  color: white;
  text-align: left;
  display: block;
}

.__react_component_tooltip a{
  color: white !important;
}

.btn-primary, .btn-secondary{
  /* border: solid 4px black; */
  /* background: var(--primary-dark); */
  border: none;
  background-color: black;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  padding: 5px;
  display: inline-block;
  /* font-style: italic; */
  position: relative;
  -webkit-transform: skew(0.97turn);
          transform: skew(0.97turn);
}

.btn-secondary{
  background-color: red;
}

.btn-primary::after, .btn-secondary::after{
  content:"\A"; 
  position: absolute;
  bottom: -3px;
  right: 0;
  width: 1em;
  height: 3px;
  background-color: red;
  display: block;
  transition: all .3s ease;
}

.btn-secondary::after{
  background-color: black;
  right: unset;
  left: 0;
}

.btn-primary:hover::after, .btn-secondary:hover::after{
  width: 100%;
}

.btn-primary.block, .btn-secondary.block{
  display: block;
  margin: auto;
}

.btn-primary:disabled, .btn-secondary:disabled{
  background-color: darkgray;
  cursor: not-allowed;
}

.btn-primary:disabled::after, .btn-secondary:disabled::after{
  background-color: lightgray;
}

.focus-on-map{
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  font-size: 1rem;
  z-index: 1;
}

.btn-google{
  background: white;
  border-radius: 25px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  box-shadow: 2px 2px 15px 2px #B2B2B2;
  border: none;
}

.btn-google img{
  width: 15px;
}

.btn-google span{
  display: inline-block;
  margin-left: .5rem;
  color: #4285F4;
  font-family: sans-serif;
  font-weight: bold;
}

.map-btn{
  z-index: 100;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 35px;
  background-color: var(--primary-dark);
  border-radius: 5px;
  border: none;
  color: white;
}

.reset-location{
  border: none;
  -webkit-transform: translateY(-.5rem);
          transform: translateY(-.5rem);
  background-color: red;
  font-size: .6rem;
  padding: 2px;
}
.avatar{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    /* transform: skew(.98turn); */
    overflow: hidden;
    margin: auto;
}

.avatar > img{
    width: 100%;
    height: 100%;
}


.__headline{
    display: flex;
    justify-content: center;
    align-items: center;
}
.__headline > *{
    display: inline-block;
    background: black;
    padding: 2px .2em;
    color: white;
    max-width: 90%;
    -webkit-transform: skew(0.995turn);
            transform: skew(0.995turn);
}

.__headline > h1{
    padding: 2px .4em;
}

.__headline > h2{
    padding: 2px .3em;
}

.__headline > h2, .__headline > h3, .__headline > h4{
    margin: 5px 0;
}


.__headline a{
    color: inherit;
    text-decoration: none;
}
.loading{
    -webkit-animation-name: loading;
            animation-name: loading;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-transform-origin: 50% top 0px;
            transform-origin: 50% top 0px;
    width: 70px;
}

@-webkit-keyframes loading {
    from {
        -webkit-transform: rotateY(0);
                transform: rotateY(0);
    }
    to{
        -webkit-transform: rotateY(360deg);
                transform: rotateY(360deg);
    }
}

@keyframes loading {
    from {
        -webkit-transform: rotateY(0);
                transform: rotateY(0);
    }
    to{
        -webkit-transform: rotateY(360deg);
                transform: rotateY(360deg);
    }
}
nav{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    box-shadow: 0px 5px 10px black;
    color: white;
    background-color: var(--primary-dark);
    z-index: 1000;
}
aside{
    position: fixed;
    top: 70px;
    bottom: 0;
    left: -100vw;
    width: 100vw;
    background-color: rgba(0,0,0,.5);
    transition: left .3s ease;
    z-index: 200;
}

aside .inner{
    padding: 1rem;
    padding-top: 2rem;
    max-width: 776px;
    margin: auto;
}

aside.open{
    left: 0;
}

aside .inner{
    opacity: 0;
    transition: all .3s ease;
    transition-delay: .3s;
}

aside.open .inner{
    opacity: 1;
    transition-delay: 0;
}

aside ul{
    max-height: calc(100vh - 70px - 50px - 95px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 1rem;
    margin-top: .5rem;
}

aside ul li{
    margin: 1rem 0;
}

aside ul li button{
    font-size: 1.5rem;
}

.btnToggleMenu{
    position: absolute;
    right: 1rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    overflow: hidden;
  }
  
  .btnToggleMenu span{
    display: block;
    width: 30px;
    height: 3px;
    background: white;
    margin: 5px 0;
    transition: all .3s ease;
  }
  
  .btnToggleMenu.active span:nth-child(1){
    -webkit-transform: translateY(6px) rotate(45deg);
            transform: translateY(6px) rotate(45deg);
  }
  .btnToggleMenu.active span:nth-child(2){
    -webkit-transform: translateX(150%);
            transform: translateX(150%);
  }
  .btnToggleMenu.active span:nth-child(3){
    -webkit-transform: translateY(-10px) rotate(-45deg);
            transform: translateY(-10px) rotate(-45deg);
  }

  .flex-space{
      display: flex;
      justify-content: space-between;
      align-items: center;
  }

  .loc{
      display: flex;
      align-items: center;
      white-space: nowrap;
  }

  nav a, aside a{
      text-decoration: none;
      color: inherit;
  }

  .claim{
      position: absolute;
      bottom: 1rem;
      left: 50%; 
      -webkit-transform: translateX(-50%); 
              transform: translateX(-50%);
      white-space: nowrap;
      width: 170px;
  }

  .go-back{
    background: none;
    color: white;
    border: none;
    position: absolute;
    left: 1rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: 1.3rem;
  }

  @media only screen and (max-width: 600px){
      aside ul li button{
          font-size: 1.2rem;
      }
  }
.badge {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .badge {
    position: relative;
    width: 55px;
    height: 55px;
    background: #ff4747;
    z-index: 1;
    
    flex-direction: column;
    justify-content: space-evenly;
    cursor: pointer;
    transition: all .3s ease;
  }
  .badge:before, .badge:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ff4747;
    z-index: -1;
  }
  .badge:before { -webkit-transform: rotate(60deg); transform: rotate(60deg); }
  .badge:after { -webkit-transform: rotate(30deg); transform: rotate(30deg); }
  .price-badge, .text-badge {
    color: #FFF;
    font-weight: bold;
    margin: 0;
    pointer-events: none;
  }
  .price-badge {
    font-size: 10px;
  }
  .text-badge {
    font-size: 10px;
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
    margin-bottom: 5%;
  }

  .badges{
      margin: 1rem 0;
  }

  .badges li{
      display: inline-block;
  }

  .badge.small{
      width: 35px;
      height: 35px;
  }

  .badge.small .text-badge{
      font-size: 7px;
  }
.store-bg{
    background-image: attr(data-bg);
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 2rem;
    background-position-y: 25%;
    padding-top: 1.5rem;
    position: relative;
    margin-bottom: 2rem;
}

.vfw{
    position: relative;
    display: table;
    margin: auto;
}

.vfw .verified_wrapper{
    position: absolute;
    right: -1.5rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.badges{
    position: absolute;
    left: 2rem;
    bottom: -2.5rem;
}

.open-maps{
    position: absolute;
    color: white;
    font-size: 1.05rem;
    top: 1rem;
    right: 1rem;
}

@media only screen and (max-width: 400px){
    .badges{
        left: 1rem;
    }
}

.ReactModal__Content {
    opacity: 0;
    -webkit-transform: translateX(-50%) translateY(150%) !important;
            transform: translateX(-50%) translateY(150%) !important;
    transition: all 300ms ease-in-out;
}
  
.ReactModal__Content--after-open {
    opacity: 1;
    -webkit-transform: translateX(-50%) translateY(-50%) !important;
            transform: translateX(-50%) translateY(-50%) !important;
}

.ReactModal__Content--before-close {
    opacity: 0;
    -webkit-transform: translateX(-50%) translateY(150%) !important;
            transform: translateX(-50%) translateY(150%) !important;
}
.rating_input-wrapper{
    display: flex;
    justify-content: center;
}

.rating_input {
    --dir: right;
    --fill: gold;
    --fillbg: rgba(100, 100, 100, 0.15);
    --heart: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 21.328l-1.453-1.313q-2.484-2.25-3.609-3.328t-2.508-2.672-1.898-2.883-0.516-2.648q0-2.297 1.57-3.891t3.914-1.594q2.719 0 4.5 2.109 1.781-2.109 4.5-2.109 2.344 0 3.914 1.594t1.57 3.891q0 1.828-1.219 3.797t-2.648 3.422-4.664 4.359z"/></svg>');
    --star: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 17.25l-6.188 3.75 1.641-7.031-5.438-4.734 7.172-0.609 2.813-6.609 2.813 6.609 7.172 0.609-5.438 4.734 1.641 7.031z"/></svg>');
    --stars: 5;
    --starsize: 3rem;
    --symbol: var(--star);
    --value: 1;
    --w: calc(var(--stars) * var(--starsize));
    --x: calc(100% * (var(--value) / var(--stars)));
    block-size: var(--starsize);
    inline-size: var(--w);
    position: relative;
    touch-action: manipulation;
    -webkit-appearance: none;
    background: none;
  }
 
  /* .rating_input::-moz-range-track {
    background: linear-gradient(to var(--dir), var(--fill) 0 var(--x), var(--fillbg) 0 var(--x));
    block-size: 100%;
    mask: repeat left center/var(--starsize) var(--star);
  }
  .rating_input::-webkit-slider-runnable-track {
    background: linear-gradient(to var(--dir), var(--fill) 0 var(--x), var(--fillbg) 0 var(--x));
    block-size: 100%;
    mask: repeat left center/var(--starsize) var(--star);
    -webkit-mask: repeat left center/var(--starsize) var(--star);
  } */
  .rating_input::-moz-range-thumb {
    height: var(--starsize);
    opacity: 0;
    width: var(--starsize);
  }
  .rating_input::-webkit-slider-thumb {
    height: var(--starsize);
    opacity: 0;
    width: var(--starsize);
    -webkit-appearance: none;
  }
  .rating_input, .rating_input-label {
    display: block;
    font-family: ui-sans-serif, system-ui, sans-serif;
  }
  .rating_input-label {
    -webkit-margin-after: 1rem;
            margin-block-end: 1rem;
  }
  
  .rating_input--nojs::-moz-range-track {
    background: var(--fillbg);
  }
  .rating_input--nojs::-moz-range-progress {
    background: var(--fill);
    block-size: 100%;
    mask: repeat left center/var(--starsize) var(--star);
  }
  .rating_input--nojs::-webkit-slider-runnable-track {
    background: var(--fillbg);
  }
  .rating_input--nojs::-webkit-slider-thumb {
    background-color: var(--fill);
    box-shadow: calc(0rem - var(--w)) 0 0 var(--w) var(--fill);
    opacity: 1;
    width: 1px;
  }
 

:root{
    --border-radius: 5px;
}

.tabs-component img {
    max-width: 100%;
    vertical-align: middle;
}
  
.tabs-component {
margin: auto;
padding: 40px;
}

.tab-links {
padding: 0;
/* margin: 0 auto 20px; */
list-style: none;
max-width: 400px;
display: flex;
justify-content: space-between;
}

.tab {
position: relative;
}

.tab a {
text-decoration: none;
color: var(--black);
}

.tab::before {
content: "";
width: 100%;
height: 100%;
opacity: 0.2;
position: absolute;
left: 0;
/* border-radius: var(--border-radius); */
background: none;
transition: background 0.5s ease;
-webkit-transform: skew(.99turn);
        transform: skew(.99turn);
}

.tab svg {
height: 15px;
width: 15px;
min-width: 15px;
fill: var(--black);
transition: fill 0.5s ease;
}

.tab.active::before {
background: var(--active-color);
}

.tab span {
font-weight: 700;
margin-left: 10px;
transition: color 0.5s ease;
}

.tab.active span {
color: var(--active-color);
}

.tab.active svg {
fill: var(--active-color);
}

.tab a {
padding: 16px;
display: flex;
align-items: center;
font-size: 1rem;
overflow: hidden;
position: relative;
}

.cards {
display: flex;
flex-wrap: wrap;
justify-content: space-between;
margin-top: 40px;
}

.content-card {
width: 48%;
margin-bottom: 26px;
}

.content-card .info::after {
content: "";
display: block;
width: 100%;
height: 3px;
bottom: -5px;
background: var(--active-color);
opacity: 0.5;
}

.content-card img {
border-radius: 6px;
}

.content-card h3 {
margin: 0 0 5px;
}

.content-card .info {
padding: 10px 0;
}


@media only screen and (max-width: 500px){
    .tabs-component{
        padding: 1rem;
        padding-top: 2rem;
    }

    .tab-links{
        max-width: 100%;
        overflow-x: scroll;
    }
}
