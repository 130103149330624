.__headline{
    display: flex;
    justify-content: center;
    align-items: center;
}
.__headline > *{
    display: inline-block;
    background: black;
    padding: 2px .2em;
    color: white;
    max-width: 90%;
    transform: skew(0.995turn);
}

.__headline > h1{
    padding: 2px .4em;
}

.__headline > h2{
    padding: 2px .3em;
}

.__headline > h2, .__headline > h3, .__headline > h4{
    margin: 5px 0;
}


.__headline a{
    color: inherit;
    text-decoration: none;
}