.ReactModal__Content {
    opacity: 0;
    transform: translateX(-50%) translateY(150%) !important;
    transition: all 300ms ease-in-out;
}
  
.ReactModal__Content--after-open {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%) !important;
}

.ReactModal__Content--before-close {
    opacity: 0;
    transform: translateX(-50%) translateY(150%) !important;
}