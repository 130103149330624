.store-bg{
    background-image: attr(data-bg);
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 2rem;
    background-position-y: 25%;
    padding-top: 1.5rem;
    position: relative;
    margin-bottom: 2rem;
}

.vfw{
    position: relative;
    display: table;
    margin: auto;
}

.vfw .verified_wrapper{
    position: absolute;
    right: -1.5rem;
    top: 50%;
    transform: translateY(-50%);
}

.badges{
    position: absolute;
    left: 2rem;
    bottom: -2.5rem;
}

.open-maps{
    position: absolute;
    color: white;
    font-size: 1.05rem;
    top: 1rem;
    right: 1rem;
}

@media only screen and (max-width: 400px){
    .badges{
        left: 1rem;
    }
}
