nav{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    box-shadow: 0px 5px 10px black;
    color: white;
    background-color: var(--primary-dark);
    z-index: 1000;
}
aside{
    position: fixed;
    top: 70px;
    bottom: 0;
    left: -100vw;
    width: 100vw;
    background-color: rgba(0,0,0,.5);
    transition: left .3s ease;
    z-index: 200;
}

aside .inner{
    padding: 1rem;
    padding-top: 2rem;
    max-width: 776px;
    margin: auto;
}

aside.open{
    left: 0;
}

aside .inner{
    opacity: 0;
    transition: all .3s ease;
    transition-delay: .3s;
}

aside.open .inner{
    opacity: 1;
    transition-delay: 0;
}

aside ul{
    max-height: calc(100vh - 70px - 50px - 95px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 1rem;
    margin-top: .5rem;
}

aside ul li{
    margin: 1rem 0;
}

aside ul li button{
    font-size: 1.5rem;
}

.btnToggleMenu{
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    overflow: hidden;
  }
  
  .btnToggleMenu span{
    display: block;
    width: 30px;
    height: 3px;
    background: white;
    margin: 5px 0;
    transition: all .3s ease;
  }
  
  .btnToggleMenu.active span:nth-child(1){
    transform: translateY(6px) rotate(45deg);
  }
  .btnToggleMenu.active span:nth-child(2){
    transform: translateX(150%);
  }
  .btnToggleMenu.active span:nth-child(3){
    transform: translateY(-10px) rotate(-45deg);
  }

  .flex-space{
      display: flex;
      justify-content: space-between;
      align-items: center;
  }

  .loc{
      display: flex;
      align-items: center;
      white-space: nowrap;
  }

  nav a, aside a{
      text-decoration: none;
      color: inherit;
  }

  .claim{
      position: absolute;
      bottom: 1rem;
      left: 50%; 
      transform: translateX(-50%);
      white-space: nowrap;
      width: 170px;
  }

  .go-back{
    background: none;
    color: white;
    border: none;
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.3rem;
  }

  @media only screen and (max-width: 600px){
      aside ul li button{
          font-size: 1.2rem;
      }
  }