html,body,#root{
  height: 100%;
}

.App {
  text-align: center;
  height: 100%;
  min-height: -webkit-fill-available;
  color: white;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.content{
  height: calc(100% - 70px);
  padding-top: 70px;
  position: relative;
  /* overflow-y: hidden; */
}

code{
  background-color: lightgray;
  padding: 1rem;
  margin: .5rem 0;
  border-radius: 5px;
  position: relative;
  white-space: nowrap;
}

code::before{
  content: "";
  position: absolute;
  top: .75rem;
  left: .5rem;
  bottom: .75rem;
  width: 1.5px;
  background: black;
}

.inner {
  max-width: 992px;
  margin: auto;
}

.rating{
  color: gold;
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  background: black;
  padding: 4px;
  z-index: 1;
}

.card-content-container.open .rating{
  font-size: 1.5rem;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.card-list {
  display: flex;
  /* flex-wrap: wrap; */
  align-content: flex-start;
  width: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
}

.card {
  position: relative;
  padding: 25px;
  height: 200px;
  flex: 0 0 40%;
  max-width: 350px;
}

/* .card:nth-child(4n + 1),
.card:nth-child(4n + 4) {
  flex: 0 0 60%;
  max-width: 60%;
}

.card:nth-child(odd) {
  padding-left: 0;
}

.card:nth-child(even) {
  padding-right: 0;
} */

.card-content-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  /* pointer-events: none; */
}

.card-content-container.open {
  top: 70px;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1;
  overflow: hidden;
  padding: 40px 0;
}

.card-content {
  pointer-events: auto;
  position: relative;
  border-radius: 20px;
  background: #1c1c1e;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.open .card-content {
  height: auto;
  max-width: 700px;
  overflow: hidden;
  /* pointer-events: none; */
}

.card-open-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.card-image-container {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  /* height: 420px;
  width: 100vw; */
}

.open .card-image-container,
.open .title-container {
  z-index: 1;
}

.title-container {
  position: absolute;
  top: 15px;
  left: 15px;
  max-width: 300px;
  text-align: left;
  padding: .5rem;
}

.title-container > h2, .title-container > h3, .title-container > h4, .title-container > h5, .title-container > .category, .title-container > .street-address{
  background: black;
  padding: 2px .2em;
}

.title-container > h2, .title-container > h3, .title-container > h4, .title-container > h5{
  display: table;
}

.open .title-container{
  transition: background .3s ease;
  transition-delay: 0s;
}

.open .title-container:hover{
  background: black;
  cursor: pointer;
  transition-delay: .5s;
}

.opened_now{
  background-color: green !important;
}

.closing_soon{
  background-color: #ff9f00 !important;
}

.opened{
  margin-bottom: .25rem;
}

.opened_next{
  display: table;
  font-weight: lighter;
  font-size: .7rem;
  background-color: black;
  padding: 2px;
}

.public_holiday{
  display: table;
  font-weight: lighter;
  font-size: .7rem;
  color: black;
  background-color: yellow;
  padding: 2px;
}



.open .title-container {
  top: 30px;
  left: 30px;
}

.category {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
}

.overlay {
  z-index: 1;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  will-change: opacity;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  /* max-width: 990px; */
}

.overlay a {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  left: 50%;

  transform: translateX(-50%);
}

.content-container {
  padding: 290px 35px 35px 35px;
  max-width: 700px;
  /* width: 90vw; */
}

p {
  color: #9d9ca1;
  font-size: 20px;
  line-height: 28px;
}

/* @media only screen and (max-width: 800px) {
  .card {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .card:nth-child(4n + 1),
  .card:nth-child(4n + 4) {
    flex: 0 0 50%;
    max-width: 50%;
  }
} */

@media only screen and (max-width: 600px) {
  .card {
     max-width: 90%;
     flex: 1 0 90%;
  }

  /* .card:nth-child(4n + 1),
  .card:nth-child(4n + 4) {
    flex: 1 0 100%;
    max-width: 100%;
  }

  .card-content-container.open {
    padding: 0;
  } */
}

@media only screen and (max-width: 500px) {
  .crop{
      max-width: 60vw;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .crop ._title{
      width: calc(60vw - 60px);
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
  }
  .crop .dots{
      display: inline-block;
  }
}

.verified_wrapper{
  display: inline-block;
  margin-left: .5rem;
  cursor: pointer;
  transform: translateY(-2px);
}

.pending{
  width: 17.5px;
  height: 17.5px;
  border-radius: 50%;
  z-index: 1;
  background: #1294f1;
  color: white;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verified {
  display: flex;
  align-items: center;
  justify-content: center;
}

.verified {
  position: relative;
  width: 15px;
  height: 15px;
  background: #2ec78f;
  z-index: 1;
  font-size: 10px;
  flex-direction: column;
  justify-content: space-evenly;
}
.verified:before, .verified:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #2ec78f;
  z-index: -1;
}
.verified:before { transform: rotate(60deg); }
.verified:after { transform: rotate(30deg); }

.__react_component_tooltip{
  padding: .5rem !important;
  pointer-events: inherit !important;
}

.__react_component_tooltip > span {
  font-size: .65rem;
  color: white;
  text-align: left;
  display: block;
}

.__react_component_tooltip a{
  color: white !important;
}

.btn-primary, .btn-secondary{
  /* border: solid 4px black; */
  /* background: var(--primary-dark); */
  border: none;
  background-color: black;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  padding: 5px;
  display: inline-block;
  /* font-style: italic; */
  position: relative;
  transform: skew(0.97turn);
}

.btn-secondary{
  background-color: red;
}

.btn-primary::after, .btn-secondary::after{
  content:"\A"; 
  position: absolute;
  bottom: -3px;
  right: 0;
  width: 1em;
  height: 3px;
  background-color: red;
  display: block;
  transition: all .3s ease;
}

.btn-secondary::after{
  background-color: black;
  right: unset;
  left: 0;
}

.btn-primary:hover::after, .btn-secondary:hover::after{
  width: 100%;
}

.btn-primary.block, .btn-secondary.block{
  display: block;
  margin: auto;
}

.btn-primary:disabled, .btn-secondary:disabled{
  background-color: darkgray;
  cursor: not-allowed;
}

.btn-primary:disabled::after, .btn-secondary:disabled::after{
  background-color: lightgray;
}

.focus-on-map{
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  font-size: 1rem;
  z-index: 1;
}

.btn-google{
  background: white;
  border-radius: 25px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  box-shadow: 2px 2px 15px 2px #B2B2B2;
  border: none;
}

.btn-google img{
  width: 15px;
}

.btn-google span{
  display: inline-block;
  margin-left: .5rem;
  color: #4285F4;
  font-family: sans-serif;
  font-weight: bold;
}

.map-btn{
  z-index: 100;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 35px;
  background-color: var(--primary-dark);
  border-radius: 5px;
  border: none;
  color: white;
}

.reset-location{
  border: none;
  transform: translateY(-.5rem);
  background-color: red;
  font-size: .6rem;
  padding: 2px;
}